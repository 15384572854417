/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-select/dist/css/bootstrap-select.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.modal-full {
    min-width: 100%;
    margin: 0;
  }
  
  .modal-fluid{
    max-width: 1500px;
  }
  
  .modal-full {
    min-width: 100%;
    margin: 0;
    height: 600px;
    }
  